/* critters:include start */
//fix themes

.drawer-footer {
	background-color: var(--primary-lighter-color) !important;
}

.drawer-header {
	background-color: var(--light-background) !important;
}

.app-dark-theme .drawer-footer {
	background-color: var(--dark-background) !important;
}

.app-dark-theme .drawer-header {
	background-color: var(--dark-background) !important;
}

.app-dark-theme.mat-drawer-container {
	background-color: var(--dark-background) !important;
	color: var(--text-primary-darker-color) !important;
}

.app-dark-theme .mat-toolbar.mat-accent {
	background-color: var(--dark-background) !important;
	color: var(--text-accent-color) !important;
}

.app-dark-theme .mat-list-item {
	background-color: var(--dark-background) !important;
	//color: var(--text-light-color) !important;
}

.tab-active {
	background-color: var(--primary-lighter-color) !important;
	color: var(--text-primary-lighter-color) !important;
	border-radius: 50px;
	overflow: hidden;
}

.app-dark-theme .tab-active {
	background-color: var(--primary-darker-color) !important;
	color: var(--text-primary-lighter-color) !important;
}

footer {
	background: linear-gradient(90deg, var(--primary-color), var(--accent-color)) !important;
	color: var(--text-primary-darker-color) !important;
}

.app-dark-theme footer {
	background: linear-gradient(90deg, var(--primary-darker-color), var(--accent-darker-color)) !important;
	color: var(--text-primary-darker-color) !important;
}

beatfulness-header mat-toolbar{
	background:transparent!important;
	color: var(--text-primary-darker-color) !important;
}

mat-sidenav-content{
	background-image: url(/assets/images/background-head.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top;
}
mat-sidenav-container{
	background-image: url(/assets/images/mask-footer.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right bottom;
	background-attachment: fixed;
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////

//global styles
$app-font-size: 24px;

html, body {
	min-height: 100%;
}

body {
	margin: 0;
}

a {
	white-space: nowrap;
	cursor: pointer;
	font-weight: bold;
	color: unset;
}

a.wrapped {
	white-space: normal;
}

a.no-decoration {
	text-decoration: none;
}

a.white-space-normal {
	white-space: normal;
}

a.font-weight-normal {
	font-weight: normal;
}

.md-warn-color {
	color: var(--warn-color) !important;
}

.md-primary-color {
	color: var(--primary-color) !important;
}

.md-accent-color {
	color: var(--accent-color) !important;
}

.md-text-accent-color {
	color: var(--text-accent-color) !important;
}

.md-dark-accent-text {
	color: var(--dark-accent-text) !important;
}

.md-warn {
	color: var(--text-warn-color) !important;
	background: var(--warn-color) !important;
}

.md-primary {
	color: var(--text-primary-color) !important;
	background: var(--primary-color) !important;
}

.md-accent {
	color: var(--text-accent-color) !important;
	background: var(--accent-color) !important;
}

.md-warn-light {
	color: var(--text-warn-lighter-color) !important;
	background: var(--warn-lighter-color) !important;
}

.mat-icon {
	font-weight: normal;
	font-style: normal;
	font-size: $app-font-size; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: "liga";
}

.md-12 {
	font-size: 12px !important;
}

.md-14 {
	font-size: 14px !important;
}

.md-18 {
	font-size: 18px !important;
}

.md-16 {
	font-size: 16px !important;
}

.md-20 {
	font-size: 20px !important;
}

.md-24 {
	font-size: 24px !important;
}

.md-30 {
	font-size: 30px !important;
}

.md-36 {
	font-size: 36px !important;
}

.md-48 {
	font-size: 48px !important;
}

.mat-icon.md-12 {
	font-size: 12px;
	height: 12px;
	width: 12px;
}

.mat-icon.md-14 {
	font-size: 14px;
	height: 14px;
	width: 14px;
}

.mat-icon.md-16 {
	font-size: 16px;
	height: 16px;
	width: 16px;
}

.mat-icon.md-18 {
	font-size: 18px;
	height: 18px;
	width: 18px;
}

.mat-icon.md-20 {
	font-size: 20px;
	height: 20px;
	width: 20px;
}

.mat-icon.md-24 {
	font-size: 24px;
	height: 24px;
	width: 24px;
}

.mat-icon.md-30 {
	font-size: 30px;
	height: 30px;
	width: 30px;
}

.mat-icon.md-36 {
	font-size: 36px;
	height: 36px;
	width: 36px;
}

.mat-icon.md-48 {
	font-size: 48px;
	height: 48px;
	width: 48px;
}

.mat-icon.md-dark {
	color: rgba(0, 0, 0, 0.54) !important;
}

.mat-icon.md-dark.md-inactive {
	color: rgba(0, 0, 0, 0.26) !important;
}

.mat-icon.md-light {
	color: rgba(255, 255, 255, 1) !important;
}

.mat-icon.md-light.md-inactive {
	color: rgba(255, 255, 255, 0.3) !important;
}

.mat-icon.md-pointer {
	cursor: pointer;
}

.mat-icon.md-valign-middle {
	vertical-align: middle;
}

.mat-icon.md-valign-sub {
	vertical-align: sub;
}

.mat-icon.md-valign-text-bottom {
	vertical-align: text-bottom;
}

.mat-icon.md-valign-top {
	vertical-align: top;
}

.mat-card-header {
	justify-content: center;
}

.dialog-responsive {
	width: 60%;
}
.md-bold{
	font-weight: bold !important;
}
.md-light-background {
	background-color: var(--light-background) !important;
}
.md-light-text-color {
	color: var(--light-text) !important;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////


@media only screen and (max-width: 599.98px) {
	.dialog-responsive {
		width: 90%;
	}
	mat-sidenav-content{
		background-size: 1000px 500px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 959.98px) {
	.dialog-responsive {
		width: 60%;
	}
	mat-sidenav-content{
		background-size: 1000px 500px;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1279.98px) {
	.dialog-responsive {
		width: 40%;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1919.98px) {
	.dialog-responsive {
		width: 40%;
	}
}

@media only screen and (min-width: 1920px) {
	.dialog-responsive {
		width: 20%;
	}
}


/* critters:include end */


.md-pointer {
	cursor: pointer !important;
}



